"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Background", {
  enumerable: true,
  get: function () {
    return _victoryCore.Background;
  }
});
Object.defineProperty(exports, "Border", {
  enumerable: true,
  get: function () {
    return _victoryCore.Border;
  }
});
Object.defineProperty(exports, "Box", {
  enumerable: true,
  get: function () {
    return _victoryCore.Box;
  }
});
Object.defineProperty(exports, "ClipPath", {
  enumerable: true,
  get: function () {
    return _victoryCore.ClipPath;
  }
});
Object.defineProperty(exports, "LineSegment", {
  enumerable: true,
  get: function () {
    return _victoryCore.LineSegment;
  }
});
Object.defineProperty(exports, "Whisker", {
  enumerable: true,
  get: function () {
    return _victoryCore.Whisker;
  }
});
Object.defineProperty(exports, "Circle", {
  enumerable: true,
  get: function () {
    return _victoryCore.Circle;
  }
});
Object.defineProperty(exports, "Rect", {
  enumerable: true,
  get: function () {
    return _victoryCore.Rect;
  }
});
Object.defineProperty(exports, "Line", {
  enumerable: true,
  get: function () {
    return _victoryCore.Line;
  }
});
Object.defineProperty(exports, "Path", {
  enumerable: true,
  get: function () {
    return _victoryCore.Path;
  }
});
Object.defineProperty(exports, "TSpan", {
  enumerable: true,
  get: function () {
    return _victoryCore.TSpan;
  }
});
Object.defineProperty(exports, "Text", {
  enumerable: true,
  get: function () {
    return _victoryCore.Text;
  }
});
Object.defineProperty(exports, "Point", {
  enumerable: true,
  get: function () {
    return _victoryCore.Point;
  }
});
Object.defineProperty(exports, "VictoryAnimation", {
  enumerable: true,
  get: function () {
    return _victoryCore.VictoryAnimation;
  }
});
Object.defineProperty(exports, "VictoryContainer", {
  enumerable: true,
  get: function () {
    return _victoryCore.VictoryContainer;
  }
});
Object.defineProperty(exports, "VictoryLabel", {
  enumerable: true,
  get: function () {
    return _victoryCore.VictoryLabel;
  }
});
Object.defineProperty(exports, "VictoryTheme", {
  enumerable: true,
  get: function () {
    return _victoryCore.VictoryTheme;
  }
});
Object.defineProperty(exports, "VictoryTransition", {
  enumerable: true,
  get: function () {
    return _victoryCore.VictoryTransition;
  }
});
Object.defineProperty(exports, "VictoryPortal", {
  enumerable: true,
  get: function () {
    return _victoryCore.VictoryPortal;
  }
});
Object.defineProperty(exports, "Portal", {
  enumerable: true,
  get: function () {
    return _victoryCore.Portal;
  }
});
Object.defineProperty(exports, "VictoryClipContainer", {
  enumerable: true,
  get: function () {
    return _victoryCore.VictoryClipContainer;
  }
});
Object.defineProperty(exports, "addEvents", {
  enumerable: true,
  get: function () {
    return _victoryCore.addEvents;
  }
});
Object.defineProperty(exports, "Collection", {
  enumerable: true,
  get: function () {
    return _victoryCore.Collection;
  }
});
Object.defineProperty(exports, "Data", {
  enumerable: true,
  get: function () {
    return _victoryCore.Data;
  }
});
Object.defineProperty(exports, "DefaultTransitions", {
  enumerable: true,
  get: function () {
    return _victoryCore.DefaultTransitions;
  }
});
Object.defineProperty(exports, "Domain", {
  enumerable: true,
  get: function () {
    return _victoryCore.Domain;
  }
});
Object.defineProperty(exports, "Events", {
  enumerable: true,
  get: function () {
    return _victoryCore.Events;
  }
});
Object.defineProperty(exports, "Helpers", {
  enumerable: true,
  get: function () {
    return _victoryCore.Helpers;
  }
});
Object.defineProperty(exports, "Log", {
  enumerable: true,
  get: function () {
    return _victoryCore.Log;
  }
});
Object.defineProperty(exports, "PropTypes", {
  enumerable: true,
  get: function () {
    return _victoryCore.PropTypes;
  }
});
Object.defineProperty(exports, "Scale", {
  enumerable: true,
  get: function () {
    return _victoryCore.Scale;
  }
});
Object.defineProperty(exports, "Style", {
  enumerable: true,
  get: function () {
    return _victoryCore.Style;
  }
});
Object.defineProperty(exports, "TextSize", {
  enumerable: true,
  get: function () {
    return _victoryCore.TextSize;
  }
});
Object.defineProperty(exports, "Transitions", {
  enumerable: true,
  get: function () {
    return _victoryCore.Transitions;
  }
});
Object.defineProperty(exports, "Selection", {
  enumerable: true,
  get: function () {
    return _victoryCore.Selection;
  }
});
Object.defineProperty(exports, "LabelHelpers", {
  enumerable: true,
  get: function () {
    return _victoryCore.LabelHelpers;
  }
});
Object.defineProperty(exports, "Axis", {
  enumerable: true,
  get: function () {
    return _victoryCore.Axis;
  }
});
Object.defineProperty(exports, "Wrapper", {
  enumerable: true,
  get: function () {
    return _victoryCore.Wrapper;
  }
});
Object.defineProperty(exports, "VictoryAccessibleGroup", {
  enumerable: true,
  get: function () {
    return _victoryCore.VictoryAccessibleGroup;
  }
});
Object.defineProperty(exports, "VictoryChart", {
  enumerable: true,
  get: function () {
    return _victoryChart.VictoryChart;
  }
});
Object.defineProperty(exports, "VictoryGroup", {
  enumerable: true,
  get: function () {
    return _victoryGroup.VictoryGroup;
  }
});
Object.defineProperty(exports, "VictoryStack", {
  enumerable: true,
  get: function () {
    return _victoryStack.VictoryStack;
  }
});
Object.defineProperty(exports, "VictoryPie", {
  enumerable: true,
  get: function () {
    return _victoryPie.VictoryPie;
  }
});
Object.defineProperty(exports, "Slice", {
  enumerable: true,
  get: function () {
    return _victoryPie.Slice;
  }
});
Object.defineProperty(exports, "VictoryArea", {
  enumerable: true,
  get: function () {
    return _victoryArea.VictoryArea;
  }
});
Object.defineProperty(exports, "Area", {
  enumerable: true,
  get: function () {
    return _victoryArea.Area;
  }
});
Object.defineProperty(exports, "VictoryBar", {
  enumerable: true,
  get: function () {
    return _victoryBar.VictoryBar;
  }
});
Object.defineProperty(exports, "Bar", {
  enumerable: true,
  get: function () {
    return _victoryBar.Bar;
  }
});
Object.defineProperty(exports, "VictoryCandlestick", {
  enumerable: true,
  get: function () {
    return _victoryCandlestick.VictoryCandlestick;
  }
});
Object.defineProperty(exports, "Candle", {
  enumerable: true,
  get: function () {
    return _victoryCandlestick.Candle;
  }
});
Object.defineProperty(exports, "VictoryErrorBar", {
  enumerable: true,
  get: function () {
    return _victoryErrorbar.VictoryErrorBar;
  }
});
Object.defineProperty(exports, "ErrorBar", {
  enumerable: true,
  get: function () {
    return _victoryErrorbar.ErrorBar;
  }
});
Object.defineProperty(exports, "VictoryHistogram", {
  enumerable: true,
  get: function () {
    return _victoryHistogram.VictoryHistogram;
  }
});
Object.defineProperty(exports, "VictoryLine", {
  enumerable: true,
  get: function () {
    return _victoryLine.VictoryLine;
  }
});
Object.defineProperty(exports, "Curve", {
  enumerable: true,
  get: function () {
    return _victoryLine.Curve;
  }
});
Object.defineProperty(exports, "VictoryScatter", {
  enumerable: true,
  get: function () {
    return _victoryScatter.VictoryScatter;
  }
});
Object.defineProperty(exports, "VictoryBoxPlot", {
  enumerable: true,
  get: function () {
    return _victoryBoxPlot.VictoryBoxPlot;
  }
});
Object.defineProperty(exports, "VictoryVoronoi", {
  enumerable: true,
  get: function () {
    return _victoryVoronoi.VictoryVoronoi;
  }
});
Object.defineProperty(exports, "Voronoi", {
  enumerable: true,
  get: function () {
    return _victoryVoronoi.Voronoi;
  }
});
Object.defineProperty(exports, "VictoryBrushLine", {
  enumerable: true,
  get: function () {
    return _victoryBrushLine.VictoryBrushLine;
  }
});
Object.defineProperty(exports, "VictoryBrushContainer", {
  enumerable: true,
  get: function () {
    return _victoryBrushContainer.VictoryBrushContainer;
  }
});
Object.defineProperty(exports, "BrushHelpers", {
  enumerable: true,
  get: function () {
    return _victoryBrushContainer.BrushHelpers;
  }
});
Object.defineProperty(exports, "brushContainerMixin", {
  enumerable: true,
  get: function () {
    return _victoryBrushContainer.brushContainerMixin;
  }
});
Object.defineProperty(exports, "VictoryCursorContainer", {
  enumerable: true,
  get: function () {
    return _victoryCursorContainer.VictoryCursorContainer;
  }
});
Object.defineProperty(exports, "CursorHelpers", {
  enumerable: true,
  get: function () {
    return _victoryCursorContainer.CursorHelpers;
  }
});
Object.defineProperty(exports, "cursorContainerMixin", {
  enumerable: true,
  get: function () {
    return _victoryCursorContainer.cursorContainerMixin;
  }
});
Object.defineProperty(exports, "VictorySelectionContainer", {
  enumerable: true,
  get: function () {
    return _victorySelectionContainer.VictorySelectionContainer;
  }
});
Object.defineProperty(exports, "SelectionHelpers", {
  enumerable: true,
  get: function () {
    return _victorySelectionContainer.SelectionHelpers;
  }
});
Object.defineProperty(exports, "selectionContainerMixin", {
  enumerable: true,
  get: function () {
    return _victorySelectionContainer.selectionContainerMixin;
  }
});
Object.defineProperty(exports, "VictoryVoronoiContainer", {
  enumerable: true,
  get: function () {
    return _victoryVoronoiContainer.VictoryVoronoiContainer;
  }
});
Object.defineProperty(exports, "VoronoiHelpers", {
  enumerable: true,
  get: function () {
    return _victoryVoronoiContainer.VoronoiHelpers;
  }
});
Object.defineProperty(exports, "voronoiContainerMixin", {
  enumerable: true,
  get: function () {
    return _victoryVoronoiContainer.voronoiContainerMixin;
  }
});
Object.defineProperty(exports, "VictoryZoomContainer", {
  enumerable: true,
  get: function () {
    return _victoryZoomContainer.VictoryZoomContainer;
  }
});
Object.defineProperty(exports, "ZoomHelpers", {
  enumerable: true,
  get: function () {
    return _victoryZoomContainer.ZoomHelpers;
  }
});
Object.defineProperty(exports, "zoomContainerMixin", {
  enumerable: true,
  get: function () {
    return _victoryZoomContainer.zoomContainerMixin;
  }
});
Object.defineProperty(exports, "RawZoomHelpers", {
  enumerable: true,
  get: function () {
    return _victoryZoomContainer.RawZoomHelpers;
  }
});
Object.defineProperty(exports, "combineContainerMixins", {
  enumerable: true,
  get: function () {
    return _victoryCreateContainer.combineContainerMixins;
  }
});
Object.defineProperty(exports, "makeCreateContainerFunction", {
  enumerable: true,
  get: function () {
    return _victoryCreateContainer.makeCreateContainerFunction;
  }
});
Object.defineProperty(exports, "createContainer", {
  enumerable: true,
  get: function () {
    return _victoryCreateContainer.createContainer;
  }
});
Object.defineProperty(exports, "VictoryTooltip", {
  enumerable: true,
  get: function () {
    return _victoryTooltip.VictoryTooltip;
  }
});
Object.defineProperty(exports, "Flyout", {
  enumerable: true,
  get: function () {
    return _victoryTooltip.Flyout;
  }
});
Object.defineProperty(exports, "VictoryLegend", {
  enumerable: true,
  get: function () {
    return _victoryLegend.VictoryLegend;
  }
});
Object.defineProperty(exports, "VictorySharedEvents", {
  enumerable: true,
  get: function () {
    return _victorySharedEvents.VictorySharedEvents;
  }
});
Object.defineProperty(exports, "VictoryAxis", {
  enumerable: true,
  get: function () {
    return _victoryAxis.VictoryAxis;
  }
});
Object.defineProperty(exports, "VictoryPolarAxis", {
  enumerable: true,
  get: function () {
    return _victoryPolarAxis.VictoryPolarAxis;
  }
});

var _victoryCore = require("victory-core");

var _victoryChart = require("victory-chart");

var _victoryGroup = require("victory-group");

var _victoryStack = require("victory-stack");

var _victoryPie = require("victory-pie");

var _victoryArea = require("victory-area");

var _victoryBar = require("victory-bar");

var _victoryCandlestick = require("victory-candlestick");

var _victoryErrorbar = require("victory-errorbar");

var _victoryHistogram = require("victory-histogram");

var _victoryLine = require("victory-line");

var _victoryScatter = require("victory-scatter");

var _victoryBoxPlot = require("victory-box-plot");

var _victoryVoronoi = require("victory-voronoi");

var _victoryBrushLine = require("victory-brush-line");

var _victoryBrushContainer = require("victory-brush-container");

var _victoryCursorContainer = require("victory-cursor-container");

var _victorySelectionContainer = require("victory-selection-container");

var _victoryVoronoiContainer = require("victory-voronoi-container");

var _victoryZoomContainer = require("victory-zoom-container");

var _victoryCreateContainer = require("victory-create-container");

var _victoryTooltip = require("victory-tooltip");

var _victoryLegend = require("victory-legend");

var _victorySharedEvents = require("victory-shared-events");

var _victoryAxis = require("victory-axis");

var _victoryPolarAxis = require("victory-polar-axis");